import {
  Box, Container, Typography, Link, LinkProps, makeStyles, Theme,
} from '@material-ui/core';
import { Link as GatsbyLink } from 'gatsby';
import React, { forwardRef } from 'react';
import Button from '../components/Button';
import SEO from '../components/seo';
import ThemeProvider from '../components/ThemeProvider';
import blobBackdrop from '../images/text-blob.svg';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    background: `url(${blobBackdrop})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '150%',
    [theme.breakpoints.up('sm')]: {
      backgroundSize: '600px',
    },
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <SEO title="Whoops 404" />
      <Box
        height="100vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        component="main"
        textAlign="center"
        className={classes.backdrop}
      >
        <Container>
          <Box mb={8}>
            <Typography variant="h1" component="h1" align="center" gutterBottom>Whoops!</Typography>
            <Typography variant="body1" align="center">
              It looks like that page doesn&apos;t exist at the moment.
              <br />
              Let&apos;s get you back on the right track!
            </Typography>
          </Box>
          <Link
            href="/"
            underline="none"
            component={
            // eslint-disable-next-line react/prop-types
            forwardRef<HTMLAnchorElement, LinkProps>(({ href, className, children }, ref) => (
              <GatsbyLink
                ref={ref as any}
                to={href as string}
                className={className}
              >
                {children}

              </GatsbyLink>
            ))
}
          >
            <Button color="purple" fullWidth={false}>
              Take me home
            </Button>
          </Link>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default NotFoundPage;
